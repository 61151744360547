import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { generateRandomNumber, numberWithCommas } from '../../utils';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { SyncLoader } from "react-spinners";
import { Loader } from '../../components/Loader/Loader';
import { useAuth } from '../../contexts/AuthProvider';
import { gaTrackEvent } from '../../utils/analytics';

export default function CheckoutModal() {
    const navigate = useNavigate();
    const [amount, setAmount] = useState()
    const [loading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); // Access the 'token' query parameter
    const loadKorapayScript = () => {
        const existingScript = document.getElementById("korapay-script");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src =
                "https://korablobstorage.blob.core.windows.net/modal-bucket/korapay-collections.min.js";
            script.id = generateRandomNumber(20);
            script.async = true;
            script.onload = () => {
                console.log("Korapay script loaded successfully.");
                setLoading(false)
                payKorapay()
            };
            document.body.appendChild(script);
        }
    };

    const payKorapay = () => {
        var data = jwtDecode(token)
        if (window.Korapay) {
            window.Korapay.initialize({
                key: "pk_live_PvKnoJ5Tac4RERk97SK5MuDRrTLqdPFGiEE9DkTG",
                reference: generateRandomNumber(10).toString(),
                amount: data?.amount,
                currency: "NGN",
                customer: {
                    name: data.name,
                    email: data.email
                },
                metadata: {
                    email: data.email,
                    deliveryMethod: data?.deliveryMethod,
                    addressId: data.addressId
                },
                notification_url: "https://606e-102-89-82-63.ngrok-free.app/api/orders/webhook",
                onClose: function () {
                    // Handle when modal is closed
                    window.location.assign("/checkout")
                  },
                  onSuccess: function (data) {
                    // Handle when payment is successful
                    setLoading(true)
                   setTimeout(() => {
                    window.location.assign("/profile")
                   }, 2000);
                  },
                  onFailed: function (data) {
                    // Handle when payment fails
                    window.location.assign("/checkout")
                  }
            });
        } else {
            console.error("Korapay script has not loaded yet.");
        }
    };

    useEffect(() => {
        if (!token) {
            window.location.assign("/checkout")
        } else {
            var data = jwtDecode(token, 'secret')
           setTimeout(()=>{
            loadKorapayScript();
           }, 1000)
          
        }

    }, []);
    const handleCopy = async (text) => {
        navigator.clipboard.writeText(text);
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 2000);
    };

    const handleTransferConfirmation = () => {
        window.fbq('track', 'Purchase', { currency: "NGN", value: amount });
        setConfirmLoading(true);
        // Add confirmation logic here
    };

    // Styles as JS objects
    const styles = {
      
        container: {
            minHeight: '100vh',
            backgroundColor: '#f7f7f7',
        },

        content: {
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        },
        userInfo: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '15px',
            marginBottom: '15px',
            borderBottom: '1px solid #ddd',
        },
        avatar: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            // marginRight: '15px',
        },
        email: {
            fontSize: '12px',
            color: '#555',
            flexGrow: 1
        },
        amountContainer: {
            //   display: 'flex',
            alignItems: 'baseline',
        },
        currency: {
            fontSize: '14px',
            fontWeight: 'bold',
            marginRight: '4px',
        },
        amount: {
            fontSize: '20px',
            fontWeight: 'bold',
        },
        transactionBreakdown: {
            fontSize: '12px',
            color: '#888',
        },
        instruction: {
            marginBottom: '15px',
            color: '#333',
        },
        detailsCard: {
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            padding: '15px',
            marginBottom: '15px',
        },
        detailRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
        },
        detailLabel: {
            color: '#666',
            fontSize: '14px',
        },
        detailValue: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        copyButton: {
            backgroundColor: '#ffa500',
            color: '#fff',
            fontSize: '12px',
            padding: '4px 8px',
            border: 'none',
            borderRadius: '4px',
            marginLeft: '12px',
            cursor: 'pointer',
        },
        expirationNote: {
            fontSize: '12px',
            color: '#666',
            marginTop: '8px',
        },
        confirmButton: {
            width: '100%',
            backgroundColor: '#ffa500',
            color: '#fff',
            padding: '14px',
            border: 'none',
            borderRadius: '8px',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: '15px',
            fontSize: '15px',
            ...(confirmLoading ? { backgroundColor: '#ddd', cursor: 'not-allowed' } : {}),
        },
        cancelButton: {
            width: '100%',
            textAlign: 'center',
            color: '#ffa500',
            fontSize: '15px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        },
        box: {
            display: 'flex',
            width: "100%",
            alignItems: 'center',
            justifyContent: "flex-end"

        }
    };

    return (loading ? <SyncLoader cssOverride={{
        position: "absolute",
        top: "50vh",
        left: "50vw",
        transform: "translate(-50%, -50%)"
    }} loading={true} color="black" /> :
        <div style={styles.container} className='x-payment'>

{/* 
            <div style={styles.content} className='payment-content'>
                <div style={styles.userInfo}>
                    <img src={"https://res.cloudinary.com/dnltxw2jt/image/upload/v1735744097/360/zpibebpd5dopnxvttltd.png"} alt="User Avatar" style={styles.avatar} />
                    <div style={styles.box}> 
                        <div style={styles.amountContainer}>
                            <div style={{ textAlign: 'right' }}><span style={styles.currency}>NGN</span>
                                <span style={styles.amount}>{numberWithCommas(amount)}</span></div>
                            <p style={styles.transactionBreakdown}>Transaction breakdown ⓘ</p>
                        </div>
                    </div>
                </div>

                <p style={styles.instruction}>
                    {confirmLoading
                        ? 'We are confirming your payment. This process could take a few minutes.'
                        : 'Proceed to your bank app to complete this transfer.'}
                </p>

                <div style={styles.detailsCard}>
                    <div style={styles.detailRow}>
                        <span style={styles.detailLabel}>AMOUNT</span>
                        <div>
                            <span style={styles.detailValue}>NGN {numberWithCommas(amount)}</span>
                            {!confirmLoading && (
                                <button
                                    style={styles.copyButton}
                                    onClick={() => handleCopy(amount)}
                                >
                                    {copiedText === amount ? 'COPIED' : 'COPY'}
                                </button>
                            )}
                        </div>
                    </div>

                    <div style={styles.detailRow}>
                        <span style={styles.detailLabel}>ACCOUNT <br /> NUMBER</span>
                        <div>
                            <span style={styles.detailValue}>9461967958</span>
                            {!confirmLoading && (
                                <button
                                    style={styles.copyButton}
                                    onClick={() => handleCopy('9461967958')}
                                >
                                    {copiedText === '9461967958' ? 'COPIED' : 'COPY'}
                                </button>
                            )}
                        </div>
                    </div>

                    <div style={styles.detailRow}>
                        <span style={styles.detailLabel}>BANK NAME</span>
                        <span style={styles.detailValue}>Wema Bank</span>
                    </div>

                    <div style={styles.detailRow}>
                        <span style={styles.detailLabel}>BENEFICIARY</span>
                        <span style={styles.detailValue}>Flutterwave/<br />360gadgetsafrica</span>
                    </div>

                    <p style={styles.expirationNote}>
                        This account detail does not expire. You can always return to this page to complete or confirm your
                        payment.
                    </p>
                </div>

                <button
                    style={styles.confirmButton}
                    onClick={handleTransferConfirmation}
                    disabled={confirmLoading}
                >
                    {confirmLoading ? 'Confirming. Please wait...' : 'I have made this bank transfer'}
                </button>

                {!confirmLoading && (
                    <button style={styles.cancelButton} onClick={() => window.location.assign('/checkout')}>
                        Cancel payment
                    </button>
                )}
            </div> */}
        </div>
    );
}
