export const initialState = {
  product: {},
  allProductsFromApi: { data: [], page: null, nextPage: null, prevPage: null },
  allCategories: [],
  search_params: "",
  promo: {}
};

export const dataReducer = (state, action) => {
  switch (action.type) {
    case "GET_ALL_PRODUCTS_FROM_API":
      return { ...state, allProductsFromApi: { data: !action.payload.page || action.payload.page == 1 ? action.payload.data : [...state.allProductsFromApi.data, ...action.payload.data], nextPage: action.payload.nextPage, prevPage: action.payload.prevPage, page: action.payload.page }, };
    case "GET_PRODUCT":
      return { ...state, product: action.payload };

    case "GET_ALL_CATEGORIES":
      return { ...state, allCategories: action.payload };

    case "UPDATE_SEARCH_PARAMS":
      return {
        ...state,
        search_params: action.payload,
      };

    case "RESET":
      return { ...state, search_params: "" };
    case "SET_PROMO":
      return { ...state, promo: action.payload };

    default:
      return state;
  }
};
