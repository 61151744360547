import React from "react";
import { useData } from "../../../../contexts/DataProvider.js";
import { Link, useNavigate } from "react-router-dom";
import "./WhyUsSection.css";
import { CgAirplane, CgRepeat, CgSmile } from "react-icons/cg";

export const WhyUsSection = () => {
  const { state, dispatch } = useData();
  const Navigate = useNavigate();
  return (
    <div>
      <h1 className="why-heading">Our Partners</h1>
      <div className="why-container">
        <h2 className="why-subtitle">
          We are able to offer reliable, high-quality, and a wide range of gadgets at competitive Prices. Courtesy of our Exclusive Trusted Vendors.
        </h2>
      <div className="x-partners">
          <img src="https://res.cloudinary.com/dnltxw2jt/image/upload/v1735746430/360/vnak6qnxoedx0w11mkie.jpg" className="x-img" />
      </div>
        {/* <h2 className="why-subtitle">
          <CgRepeat /> 100% Return and Exchange policy 
        </h2>
        <h2 className="why-subtitle">
          <CgSmile /> Customer satisfaction is guaranteed
        </h2> */}
        <button
          onClick={() => Navigate("product-listing")}
          className="shop-now-btn btn-2"
        >
          Shop Now
        </button>
      </div>
    </div>
  );
};
