import { useSearchParams } from 'react-router-dom'; 

// Function to update or add a search parameter
const useUpdateSearchParam = () => { 
  const [searchParams, setSearchParams] = useSearchParams(); 
 
  const updateSearchParam = (key, value, type) => {
    // Update or set the search parameter
          var item  = searchParams.get(key)
          if (type != 'delete') {
        if(Array.isArray(value)){
            if(item) {
                value = item + ','+value[0]
            }  else {
                value = value[0]
            }
        }
      searchParams.set(key, value) 
      
    } else {
        console.log(key)
        if(Array.isArray(value)){
             
                  let list = item?.split(',') || [];
                  if(list.length > 1) {
                      list = list.filter(i => i !== value[0]);
                      searchParams.set(key, list.join(','));
              }  else {
                  searchParams.delete(key);
              }
          } else  searchParams.delete(key);
      // Remove the search parameter if value is empty or null
    }

    // Update the URL with the new search parameters
  
    setSearchParams(searchParams);
  };
 
  return updateSearchParam;
};

export default useUpdateSearchParam;