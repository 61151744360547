import React, { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
 

const faqData  = [
  {
    question: "What is 360gadgetsafrica?",
    answer: "360gadgetsafrica is an ecommerce platform for gadgets and accessories. Our mission is to make gadgets shopping easy, affordable, and accessible across Africa. We are only available in Nigeria for now."
  },
  {
    question: "Where are you located?",
    answer: "360GadgetsAfrica is proudly located in the indigenous city of Ilorin, Kwara State with business name registration no. 7927753 from CAC. Our roots in this historic city inspire our commitment to delivering innovative technology across Nigeria and Africa by partnering with a closed network of gadget suppliers across africa. While we operate as an online store, our team in Ilorin ensures that we provide top-notch service and support to all our customers."
  },
  {
    question: "What location do you deliver to",
    answer: "We currently deliver gadgets to Lagos, Abuja, Ibadan, Port-Harcourt, Kaduna, Kano, and other states in Nigeria. There are ongoing plans to expand to other countries in Africa soon."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept a variety of payment methods through secure payment gateways like Flutterwave and Paystack including credit/debit cards, bank transfers, and mobile payments. Your payment details are secure"
  },
  {
    question: "When will i get my item",
    answer: "At 360GadgetsAfrica, we prioritize fast and reliable delivery. Once your order is confirmed, you can expect to receive your item within 24-48hours (depending on your location and delivery method). You will receive a tracking number via email or SMS to monitor your order’s progress. We work with trusted delivery partners to ensure that your gadgets arrive safely and on time. "
  },

  {
    question: "What if i need help and assistance",
    answer: "We’re here to help! If you need any assistance with your order, product inquiries, or anything else, our customer support team is always ready to assist you. Contact us at support@360gadgetsafrica.com or chat with us through the live chat"
  },

]
export default function FAQAccordion() {
  const [openItems, setOpenItems] = useState([])

  const toggleItem = (index) => {
    setOpenItems(prevOpenItems =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter(item => item !== index)
        : [...prevOpenItems, index]
    )
  }

  return (
    <div className="x-faq">
      {faqData.map((faq, index) => (
        <div key={index} className="x-faq-card">
          <button
            className="x-faq-question"
            onClick={() => toggleItem(index)}
            aria-expanded={openItems.includes(index)}
          >
            <span className="x-faq-question-text">{faq.question}</span>
            {openItems.includes(index) ? (
              <BsChevronDown className="h-5 w-5 text-gray-500" />
            ) : (
              <BsChevronUp className="h-5 w-5 text-gray-500" />
            )}
          </button>
          {openItems.includes(index) && (
            <div className="x-faq-answer">
              <p className="x-faq-answer-text">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}