import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserData } from "../../contexts/UserDataProvider.js";

export const RequiresAuth = ({ children }) => {
  const token = window.localStorage.getItem("token")
  var user = window.localStorage.getItem("user")
  if(user) user = JSON.parse(user)
  const location = useLocation();
  return token && user.verificationCode ? 
  <Navigate to="/verification" state={{ from: location }} />
  : token ?
  ( 
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};


export const RequiresCartProducts = ({ children }) => {
  const { userDataState } = useUserData();

  const location = useLocation();
  return userDataState.cartProducts.length  ? (
    children
  ) : (
    <Navigate to="/cart" state={{ from: location }} />
  );
};