import AxiosCall from "../../controller/axios";

export const loginService = async ({ email, password, oauthToken, appleToken}) => AxiosCall({
  method: "POST",
  path: "api/users/signin",
  data: { email, password, oauthToken , appleToken}
})

export const verifyOtp = async (details) => AxiosCall({
  method: "POST",
  path: "api/users/verifyOtp",
  data: details
})

export const sendOtp = async (details) => AxiosCall({
  method: "POST",
  path: "api/users/sendOtp",
  data: details
})
export const getVendorAccount = async () => AxiosCall({
  method: "GET",
  path: "api/venders/account",
})
export const refreshTokenService = async () => AxiosCall({
  method: "GET",
  path: "api/users/refreshToken",
})