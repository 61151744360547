import React from "react";
import { Footer } from "../../components/Footer/Footer";
import { HeroSection } from "./components/HeroSection/HeroSection";
import { CategoriesSection } from "./components/CategoriesSection/CategoriesSection";
import { VideosSection } from "./components/VideosSection/VideosSection";
import { HeroVideo } from "./components/HeroVideo/HeroVideo";
import { useData } from "../../contexts/DataProvider.js";
import { WhyUsSection } from "./components/WhyUs/WhyUsSection.jsx";
import { Helmet } from "react-helmet";

export const Home = () => {
  const { loading } = useData();
  return (
    <>
      <Helmet>
        <title>360gadgetsafrica - Gadgets & Accessories All in One Store</title>
        <meta name="Shop and enjoy low prices, Great deals and 24hrs free delivery of gadgets and accessories to your city" />
      </Helmet>
      {!loading && (
        <div className="home-page">

          <div className="hero">
            <HeroVideo />
            <HeroSection />
            <VideosSection />
            <CategoriesSection />
            <WhyUsSection />
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};
