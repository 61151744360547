import React from "react";
import ReactPlayer from "react-player";
import "./VideosSection.css";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";

export const VideosSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="video-container">
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card" >
          <div>
          <img onClick={()=>navigate('/product-details/Samsung-S22-Ultra-128GB-5G--19689')} 
          src="https://terra01.s3.amazonaws.com/images/3de86e1b-a529-4776-bc24-184e7eb3087a.jpeg"
          />
          <h3>Galaxy S22 Ultra</h3>
          <span className="notch"></span>
          </div>
        </Tilt>{" "}
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          <div onClick={()=>navigate("/product-details/Dell-XPS-9360-%7C%7C-Intel-Core-i7-7500U-7th-Generation-%7C%7C--62498")} >
          <img  src="https://terra01.s3.amazonaws.com/images/be72cd5a-1087-4d1e-8de4-e2df65580fb7.jpeg"/>
          <h3>Dell XPS</h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
          
        <div onClick={()=>navigate('/product-details/HP-EliteBook-1040-G6-x360--45141')} >
          <img src="https://terra01.s3.amazonaws.com/images/WhatsApp%20Image%202024-08-12%20at%2011.24.23%20AM.jpeg" alt="" className="src" />
          <h3>Hp Elitebook </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        <Tilt transitionSpeed={1000} scale={1.07} className="video-card">
        <div onClick={()=>navigate('/product-details/iPhone-12-128GB-FU--15394')}> 
         <img src="https://terra01.s3.amazonaws.com/images/2fa3e6d0-0767-44ad-98ac-e2e8ddf255a4.jpeg" alt="" className="src" />
          <h3>Iphone 12  </h3>
          <span className="notch"></span>
          </div>
        </Tilt>
        
      </div>
    </>
  );
};
