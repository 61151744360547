import AxiosCall from "../../controller/axios";


export const getWishlists = async () => {
    return AxiosCall({
        method: "GET",
        path: "api/wishlists",
    })
};

export const addToWishlists = async (product) => {
    return AxiosCall({
        method: "POST",
        path: "api/wishlists",
        data: product,
    })
}; 
export const removeFromWishlists = async (wishId) => {
    return AxiosCall({
        method: "DELETE",
        path: "api/wishlists/"+wishId,
    })
};

