
import { useState } from 'react'
import './Verification.css'
import { useAuth } from '../../../contexts/AuthProvider';
import { CircleLoader, PulseLoader } from 'react-spinners';

export default function Verification() {
  const {otpCode, setOtpCode , verifyOtpCode, sendOtpMail, otpLoading, auth} = useAuth();
  const [disabled, setDisabled ] = useState(false)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    verifyOtpCode()
    // Handle verification submission
  }

  return (
    <div className="verification-container">
      <div className="verification-box">
   
        <h1>Confirm your identity</h1>
        
        <p className="instruction">
          To proceed, check your email on your 
          device to view your valid verification code, then fill-in below.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="verification-code">Your Verification Code</label>
            <input
              type="text"
              id="verification-code"
              
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              placeholder=""
              minLength={5}
              maxLength={5}
            />
          </div>

          <button type="submit" className="submit-button">
           {otpLoading && <PulseLoader color="#fff" size={"8px"} style={{ position: 'relative'  }}    />} Log in
          </button>
        </form>
        {disabled ? null :
        <div className="recovery-link">
          <span>Didnt receive OTP Code? </span>
          <a href="#" onClick={()=>{
                    sendOtpMail()
                    setDisabled(true)
                  }}>Resend code</a>
        </div>}
      </div>
    </div>
  )
}

