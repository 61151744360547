import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { RxClipboard, RxCross2, RxGrid, RxListBullet } from "react-icons/rx";
import { useState } from "react";

import React from "react";
import "./Filter.css";
import { useData } from "../../../../contexts/DataProvider.js";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import useUpdateSearchParam from '../SearchParams.jsx';

export const Filter = () => {
  const { dispatch, state  } = useData();
  const {setDisplayType, listDisplayType} = useUserData();
  const [isFilterMenuOn, setIsFilterMenuOn] = useState(false);
  const {search_params, allCategories} = state
  const updateSearchParam = useUpdateSearchParam();
//  const uSearchParam = new URLSearchParams(window.location.search)
//  const updateSearchParam = (key, value, del)=> {
//   uSearchParam.append(key, value)
//   if(!del)   uSearchParam.toString()
//     else  uSearchParam.delete(key)
//   dispatch({
//     type: "UPDATE_SEARCH_PARAMS",
//     payload:  uSearchParam.toString()
//   })
//  }
  return (
    <div className={state?.promo?.priceDrop ? "x-filter x-pad6 " : "x-filter"}>
      <div
        className={
          isFilterMenuOn
            ? "filter-container filter-container-mobile-open"
            : "filter-container filter-container-mobile-closed"
        }
      >
        <div
          className={
            !isFilterMenuOn
              ? "filter-header filter-header-mobile-closed"
              : "filter-header filter-header-mobile-open"
          }
        >
          <span
            className="close-tab"
            onClick={() => setIsFilterMenuOn(!isFilterMenuOn)}
          >
            {!isFilterMenuOn ? <TbAdjustmentsHorizontal /> : <RxCross2 />}
          </span>
          <h2>Filters</h2>

          <button
            className={isFilterMenuOn ? "reset-btn" : "reset-btn-hide"}
            onClick={() =>
              dispatch({
                type: "RESET",
                payload: ""
              })
            }
          >
            Reset
          </button>
        </div>
        <div className="display-option">
          <span className={listDisplayType == 'product-grid' ? 'active' : ''} onClick={()=>setDisplayType('product-grid')}><RxGrid /></span>
          <span className={listDisplayType == 'product-row' ? 'active' : ''}  onClick={()=>setDisplayType('product-row')}><RxListBullet /></span>
          <span className={listDisplayType == 'product-column' ? 'active' : ''}   onClick={()=>setDisplayType('product-column')}><RxClipboard /></span>
        </div>

        <div
          className={
            isFilterMenuOn
              ? "filter-types-container filter-types-container-mobile"
              : "filter-types-container"
          }
        >

<div className="category-container">
            <h3>Categories</h3>
            <div className="category-input-container">
              {allCategories?.map(({ title , _id, slug}) => (
                <div className="category-input-container" key={_id}>
                  <label htmlFor={`category-${_id}`}>
                    {`${title}`}
                    <input
                      // checked={state.filters.categories.includes(_id)}
                  checked={search_params.includes(_id)}
                  onChange={(e) => {
                        // dispatch({
                        //   type: "ADD_CATEGORIES",
                        //   payload: _id,
                        // }) 
                    e.target.checked ? updateSearchParam("categoryId", [_id]) : updateSearchParam("categoryId", [_id] , 'delete')
                        
                      }
                       
                      }
                      id={`category-${_id}`}
                      type="checkbox"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="price-container">
            <h3>Price</h3>
            <div className="price-input-container">
              <label htmlFor="below-200">
                Below ₦3000
                <input
                  // checked={state.filters.price.find((price) =>
                  //   price.min === 0 ? true : false
                  // )}
                  checked={search_params.includes("0--3000")}
                  onChange={(e) =>
                    e.target.checked ? updateSearchParam("original_price", '0--3000') : updateSearchParam("original_price", '0--3000', 'delete')
                  }
                  id="below-3000"
                  type="checkbox"
                />
              </label>

              <label htmlFor="201-999">
              ₦3001 - ₦5000
                <input
                  // checked={state.filters.price.find((price) =>
                  //   price.min === 3001 ? true : false
                  // )}
                  checked={search_params.includes("3001--5000")}

                  onChange={(e) =>
                    // dispatch({
                    //   type: "ADD_PRICE",
                    //   payload: { min: 3001, max: 5000 },
                    // })
                    e.target.checked ? updateSearchParam("original_price", '3001--5000') : updateSearchParam("original_price", '3001--5000', 'delete')

                  }
                  id="3001-5000"
                  type="checkbox"
                />
              </label>

              <label htmlFor="5001-7000">
              ₦5001 - ₦7000
                <input
                  // checked={state.filters.price.find((price) =>
                  //   price.min === 5001 ? true : false
                  // )}
                  checked={search_params.includes("5001--7000")}
                  onChange={(e) =>
                    // dispatch({
                    //   type: "ADD_PRICE",
                    //   payload: { min: 5001, max: 7000 },
                    // })
                    e.target.checked ? updateSearchParam("original_price", '5001--7000') :  updateSearchParam("original_price", '5001--7000', 'delete')
                  }
                  id="5001-7000"
                  type="checkbox"
                />
              </label>

              <label htmlFor="above 7000">
                Over ₦7000
                <input
                  // checked={state.filters.price.find((price) =>
                  //   price.min === 7001 ? true : false
                  // )}
                  checked={search_params.includes("7001--1500")}

                  onChange={(e) =>
                    // dispatch({
                    //   type: "ADD_PRICE",
                    //   payload: { min: 7001, max: 15000 },
                    // })
                    e.target.checked ? updateSearchParam("original_price", '7001--1500') : updateSearchParam("original_price", '7001--1500', 'delete')
                  }
                  id="above 7000"
                  type="checkbox"
                />
              </label>
            </div>
          </div>

          <div className="ratings-container ratings-container-mobile">
            <h3>Ratings (min)</h3>
            <div className="input-range">
              <datalist id="markers">
                <option label="0" value="0">
                  0
                </option>
                <option label="2.5" value="2.5">
                  2.5
                </option>
                <option label="5.0" value="5">
                  5
                </option>
              </datalist>
              <input
                step="0.1"
                onChange={(e) =>
                  // dispatch({
                  //   type: "ADD_RATINGS",
                  //   payload: Number(e.target.value),
                  // })
                  updateSearchParam("rating",  Number(e.target.value))

                }
                // list="markers"
                id="price"
                type="range"
                min="0"
                max="5.0"
                defaultValue={3}
                // value={state.filters.rating}
              />
            </div>
          </div>


          <div className="sorting-container">
            <h3>Sort by price</h3>

            <div className="sorting-input-container">
              <label htmlFor="high-to-low">
                Price-high to low
                <input
                  checked={search_params.includes("highToLow")}
                  onChange={(e) =>
                    // dispatch({ type: "ADD_SORT", payload: "highToLow" })
                    updateSearchParam("sort",  "highToLow")

                  }
                  name="sort"
                  id="high-to-low"
                  type="radio"
                />
              </label>

              <label htmlFor="low-to-high">
                Price-low to high
                <input
                  // checked={state.filters.sort === "lowToHigh"}
                  checked={search_params.includes("lowToHigh")}
                  onChange={() =>
                    // dispatch({ type: "ADD_SORT", payload: "lowToHigh" })
                    updateSearchParam("sort",  "lowToHigh")
                  }
                  name="sort"
                  id="low-to-high"
                  type="radio"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
