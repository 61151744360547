import React from 'react'
import "./Swap.css"
import { Footer } from '../../components/Footer/Footer'
import { RiCheckFill } from 'react-icons/ri'

export default function SwapSuccess() {
    return (
        <>
            <div className="swap-container">
                <div className="policy-container">
                    <div className="policy-heading">
                        <h2><RiCheckFill /> Thank You! Your Submission Was Successful</h2>
                        <h3>We Appreciate Your Submission</h3>
                    </div>
                    <div className="policy-desc">
                        <p> We’ve received your information and will process it shortly. we’re excited to assist you.</p>
                    </div>
                    <div className="policy-heading">
                        <h3>What Happens Next?</h3>
                    </div>
                    <div className="policy-desc">
                        <p>Here’s what you can expect after your submission:</p>
                        <ul>
                            <li><b>Confirmation Email : </b>
                                You’ll receive a confirmation email shortly, summarizing the details of your submission. If you don’t see it in your inbox, please check your spam or junk folder.</li>
                            <li><b>Review and Follow-Up : </b>
                                Our team will review your submission and get back to you as soon as possible. We aim to provide a response within 5 business days. If we need any additional information, we’ll reach out to you directly.</li>
                            <li><b>Stay Connected : </b>
                                Keep an eye on your inbox for updates and additional information. you’ll also start receiving our latest news and offers.</li>
                        </ul>
                    </div>
                    <div className="policy-heading">
                        <h3><b>Need Immediate Assistance?</b></h3>
                    </div>
                    <div className="policy-desc">
                        <p>If you have any urgent questions or need further assistance, feel free to contact our support</p>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
