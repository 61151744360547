import AxiosCall from "../../controller/axios"

export const getWallet = async () => {
    return AxiosCall({
        method: 'GET',
        path: 'api/wallets'
    })
}

export const fetchDataPlan = async () => {
    return AxiosCall({
        method: 'GET',
        path: 'api/wallets/fetchDataPlan'
    })
}

export const purchaseData = async (plan) => {
    return AxiosCall({
        method: 'POST',
        path: 'api/wallets/buyDataPlan',
        data: plan
    })
}
export const purchaseAirtime = async (plan) => {
    return AxiosCall({
        method: 'POST',
        path: 'api/wallets/buyAirtime',
        data: plan
    })
}

export const fetchBanks = async () => {
    return AxiosCall({
        method: 'GET',
        path: 'api/wallets/fetchBanks',
    })
}

export const verifyBank = async (details) => {
    return AxiosCall({
        method: 'POST',
        path: 'api/wallets/verifyBank',
        data: details
    })
}
export const fetchTrans = async () => {
    return AxiosCall({
        method: 'GET',
        path: 'api/wallets/transactions',
    })
}

export const withdraw = async (details) => {
    return AxiosCall({
        method: 'POST',
        path: 'api/wallets/withdraw',
        data: details
    })
}