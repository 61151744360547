import "./ProductImage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Reviews } from "../Reviews/Reviews";
import { MoreInfo } from "../MoreInfo/MoreInfo";
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: window.innerWidth <= 425 ? 1 : 2,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  nextArrow: null,
  prevArrow: null
};

export const ProductImage = ({ selectedProduct }) => {
  const [currImg, setCurrImg] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  var arr = selectedProduct?.images.map(img => ({ src: img }))
  return (
    <div
      className="product-details-image"
    >
      {/* <ImgsViewer
        imgs={arr}
        isOpen={openModal}
        onClickPrev={() => { setCurrImg(currImg - 1) }}
        onClickNext={() => { setCurrImg(currImg + 1) }}
        onClose={() => { setOpenModal(false) }}
        currImg={currImg}
      /> */}
         <Lightbox
                open={openModal}
                close={() => setOpenModal(false)}
                slides={arr}
            />
      <div className="x-thumbnail">
        <Slider {...settings} >
          {
            selectedProduct?.images.map((img, key) => (
              <div key={key} onClick={() => {
                setCurrImg(key)
                setOpenModal(true)
              }
              }> <img key={key} src={img} alt={selectedProduct.title} /> </div>
            ))
          }
          {/* <div><h3>tes 1</h3></div> */}
          {/* <div><h3>test 2</h3></div> */}
          {/* <div> <img src={selectedProduct?.images[0]} alt={selectedProduct.title}/> </div> */}
        </Slider>
      </div>
     {selectedProduct.description && <div className="description-container">
      <h3>Description</h3>
      <p >
       {selectedProduct?.description}
      </p>
      </div>}
      {window.innerWidth > 425 && <MoreInfo />}
      {/* {window.innerWidth > 425 && <Reviews productId={selectedProduct?._id} comments={selectedProduct?.comments}/>} */}
      </div>
  );
};
