import React from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider.js";
import { useUserData } from "../../../contexts/UserDataProvider.js";
import { googleLogout } from '@react-oauth/google';

import "./Logout.css";

export const Logout = () => {
  const { dispatch } = useUserData();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const logoutHandler = () => {
    localStorage.clear("");
    toast.success("You're logged out successfully!");
    googleLogout();
    window.location.assign("/");
  };
  return (
    <div className="logout-container">
      <button onClick={logoutHandler}>Logout</button>
    </div>
  );
};
