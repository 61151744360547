import React from "react";
import "./Footer.css";
import { ImGithub } from "react-icons/im";
import { SiLinkedin } from "react-icons/si";
import { BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CgChevronDown, CgFacebook, CgInstagram, CgTwitter, CgYoutube } from "react-icons/cg";
import { useAuth } from "../../contexts/AuthProvider";
import { gaTrackEvent } from "../../utils/analytics";

export const Footer = () => {
  const copyrightYear = new Date().getFullYear();
  const { auth, setIsLoginModalOpen } = useAuth();

  return (

    <footer>
      <div className="footer">
      <div className="x-downloadimg">
              <a href="https://apps.apple.com/app/360gadgetsafrica/id6736353137?platform=iphone" target="_blank">
                <img src="https://res.cloudinary.com/dnltxw2jt/image/upload/v1731154161/ntsv2brfd8lboncvjavk.png"
                  alt="Download on the App Store"
                  // style={{ width: "100px" }} 
                  />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.gadgetsafrica.gadgetsafrica" target="_blank">
                <img src="https://res.cloudinary.com/dnltxw2jt/image/upload/v1731153650/ibfyv6toskeeikywuuv5.webp"
                  alt="Download on Play Store"
                  // style={{ width: '100px' }}
                   />
              </a>
            </div>
        <div className="row">
          <div>
        
            <a href="https://instagram.com/360gadgetsafrica" onClick={() => gaTrackEvent({ label: 'instagram_view', category: 'social_view', type: 'instagram_view' })} target="_blank" className="icon"><CgInstagram /></a>
            <a href="https://facebook.com/360gadgetsafrica" target="_blank" onClick={() => gaTrackEvent({ label: 'facebook_view', category: 'social_view', type: 'facebook_view' })} className="icon"><CgFacebook /></a>
            <a href="https://twitter.com/360gadgetafrica" target="_blank" onClick={() => gaTrackEvent({ label: 'twitter_view', category: 'social_view', type: 'twitter_view' })} className="icon"><CgTwitter /></a>
            {/* <a href="#" className="icon"><CgYoutube /></a> */}
          </div>
          <div><Link
            className="x-country"
            to="#"
          >
            🇳🇬 NGN <CgChevronDown />
          </Link></div>
        </div>

        <div className="row">
          <ul>

          
            <li><Link to={auth.isAuth ? '/swap' : "#"} onClick={() => {
              !auth.isAuth && setIsLoginModalOpen(true)
            }}>Swap deals</Link></li>
            {!auth.isAuth && <li><Link to="/contact" onClick={() => {
              !auth.isAuth && setIsLoginModalOpen(true)
            }}>Signup</Link></li>}
            <li><Link to="/contact">Contact us</Link></li>
            <li><Link to="/return-policy">Return Policy</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/shipping-policy">Shipping Policy</Link></li>
            <li><Link to="/terms">Terms & Conditions</Link></li>

          </ul>
        </div>

        <div className="x-row">
          360gadgetsafrica  © {copyrightYear}  - All rights reserved
        </div>
      </div>
    </footer>
  );
};
