import React from "react";
import { useUserData } from "../../contexts/UserDataProvider.js";
import { BsFillStarFill } from "react-icons/bs";
import "./Wishlist.css";
import { Link, useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataProvider.js";
import { Helmet } from "react-helmet";

export const Wishlist = () => {
  const navigate = useNavigate();

  const {
    userDataState,
    isProductInCart,
    addToCartHandler,
    wishlistHandler,
    cartCountHandler,
  } = useUserData();

  const { loading } = useData();

  return (
    <>
     <Helmet>
        <title>360gadgetsafrica - Wishlist</title>
        <meta name="Shop your favourite gadgets and accessories" />
      </Helmet>
    {!loading &&
    (userDataState.wishlistProducts.length ? (
      <div>
        <h1 className="page-heading">Wishlist</h1>
        <div className="wishlist-products-container">
          {userDataState.wishlistProducts?.map(({productId, _id}) => (
            <div className="wishlist-card" key={productId.title}>
              <div>
                <img
                  className="img-container"
                  alt={productId.title}
                  src={productId.images[0]}
                />
              </div>
              <Link to={`/product-details/${productId.slug}`}>
              <div className="product-card-details">
                <h3>{productId.title}</h3>
                <p className="ratings">
                  {productId.rating['$numberDecimal']}
                  <BsFillStarFill color="orange" /> ({productId.reviews} reviews){" "}
                </p>
                <div className="price-container">
                  <p className="original-price">₦{productId.original_price}</p>
                  <p className="discount-price">₦{productId.discounted_price}</p>
                </div>

                {/* <p>Gender: {productId.category_name}</p> */}
                <div className="info">
                  {productId.is_stock < 1 && (
                    <p className="out-of-stock">Out of stock</p>
                  )}
                  {productId.trending && <p className="trending">Trending</p>}
                </div>
              </div>
                </Link>
              <div className="wishlist-btn-container">
                {/* <button
                  className="cart-wishlist-btn"
                  onClick={() =>addToCartHandler(productId)
                  }
                >
                  {!isProductInCart(productId) ? "Add to Cart" : `Go to Cart`}
                </button> */}
                <button
                  className="remove-from-wishlist-btn"
                  onClick={() => wishlistHandler({productId: productId._id, title: productId.title})}
                >
                  Remove from Wishlist
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="wishlist-empty-container">
        <h2 className="page-heading">Wishlist is Empty</h2>
        <button onClick={() => navigate("/product-listing")}>Explore</button>
      </div>
    ))}
    </>
  );
};
