import "./AddressModal.css";
import React from "react";

import { useState } from "react";
import { addAddressService } from "../../../../services/address-services/addAddressService";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { updateAddressService } from "../../../../services/address-services/updateAddressService";
import { toast } from "react-hot-toast";
import { useAddress } from "../../../../contexts/AddressProvider.js";
import { useAuth } from "../../../../contexts/AuthProvider.js";
import { addAddress, updateAddress } from "../../../../services/address-services/index.js";
import { RxCross2 } from "react-icons/rx";

export const AddressModal = () => {
  const [, setLoading] = useState(false);
  const [, setError] = useState("false");
  const { auth } = useAuth();
  const { dispatch, userDataState } = useUserData();
 
  const {
    setIsAddressModalOpen,
    addressForm,
    setAddressForm,
    isEdit,
    setIsEdit,
  } = useAddress();

  const updateAddressHandler = async (address) => {
    try {
      setLoading(true);
      setError("");
      const response = await updateAddress(address);
        console.log("edit address", response);
        setLoading(false);
        toast.success(` ${address.name}'s address updated successfully!`);
        dispatch({ type: "SET_ADDRESS", payload: userDataState.addressList?.map((item)=> {
          if( item._id == address._id ){
           item = {...item, ...address}
          }
          return item
         })});
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addAddressHandler = async (address) => {
    try {
      setLoading(true);
      setError("");
      const response = await addAddress(address);
  
        setLoading(false);
        toast.success("New address added successfully!");
        dispatch({ type: "SET_ADDRESS", payload:[...userDataState.addressList,  response.data] });
 
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="address-modal-container">
      <div className="address-input-container">
        <div className="address-content">
        <h2>Address Form</h2>
        <div
          className="cross-tab-icon cross-tab-icon-mobile"
          onClick={() => setIsAddressModalOpen(false)}
        >
          <RxCross2 color={"rgb(106, 106, 65)"} size={25} />
        </div>
        <form
          onSubmit={(e) => {
            if (!isEdit) {
              e.preventDefault();
              addAddressHandler(addressForm);
              setAddressForm({
                name: "",
                street: "",
                city: "",
                state: "",
                country: "Nigeria",
                pincode: "",
                phone: "",
              });
              setIsAddressModalOpen(false);
            } else {
              e.preventDefault();
              updateAddressHandler(addressForm);
              setAddressForm({
                name: "",
                street: "",
                city: "",
                state: "",
                country: "Nigeria",
                pincode: "",
                phone: "",
              });
              setIsAddressModalOpen(false);
              setIsEdit(false);
            }
          }}
          className="input-container"
        >
            <div className="name-container">
            <label htmlFor="first-name">Full Name</label>
          <input
            name="name"
            value={addressForm.name}
            required
            onChange={(e) =>
              setAddressForm({ ...addressForm, name: e.target.value })
            }
            placeholder="Enter Name"
          />
          </div>

          <div className="name-container">
            <label htmlFor="first-name">Street Name</label>
          <input
            required
            value={addressForm.street}
            onChange={(e) =>
              setAddressForm({ ...addressForm, street: e.target.value })
            }
            placeholder="Enter Street"
          />
          </div>

          <div className="name-container">
            <label htmlFor="first-name">City </label>
          <input
            name="city"
            required
            value={addressForm.city}
            onChange={(e) =>
              setAddressForm({ ...addressForm, city: e.target.value })
            }
            placeholder="Enter City"
          />
          </div>

          <div className="name-container">
            <label htmlFor="first-name">State</label>
          <input
            name="state"
            required
            value={addressForm.state}
            onChange={(e) =>
              setAddressForm({ ...addressForm, state: e.target.value })
            }
            placeholder="Enter State"
          />
          </div>

          {/* <div className="name-container">
            <label htmlFor="country-name">Country</label>
          <input
            name="country"
            value={addressForm.country}
            required
            onChange={(e) =>
              setAddressForm({ ...addressForm, country: e.target.value })
            }
            placeholder="Enter Country"
          />
          </div> */}

          {/* <div className="name-container">
            <label htmlFor="first-name">First Name</label>
          <input
            name="pincode"
            value={addressForm.pincode}
            required
            onChange={(e) =>
              setAddressForm({ ...addressForm, pincode: e.target.value })
            }
            placeholder="Enter Pincode"
          />
          </div> */}

          <div className="name-container">
            <label htmlFor="phone">Phone</label>
          <input
            name="phone"
            value={addressForm.phone}
            required
            onChange={(e) =>
              setAddressForm({ ...addressForm, phone: e.target.value })
            }
            placeholder="Enter Phone"
            minLength="8"
          />
          </div>
          <div className="name-container">
          <input className="submit" type="submit" value="Save" />
            </div>
        </form>
        <div className="btn-container">
          {/* <button onClick={() => setIsAddressModalOpen(false)}>Cancel</button> */}
          {/* <button
            onClick={() => {
              setAddressForm({ ...dummyAddress });
            }}
          >
            Add Dummy Data
          </button> */}
        </div>
      </div>
      </div>
    </div>
  );
};
