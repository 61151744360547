import React from "react";
import { MdDelete } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import "./CartListing.css";

import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { numberWithCommas } from "../../../../utils/index.js";

export const CartListing = () => {
  const {
    userDataState,
    isProductInWishlist,
    removeFromCartHandler,
    wishlistHandler,
    cartCountHandler,
    cartLoading,
  } = useUserData();

  return (
    <div className="cart-products-container">
      {userDataState.cartProducts.map(({ productId: { title, images, discounted_price, vendorId, _id: prodId }, qty, _id ,size}) => (
        <div className="cart-product-card" key={_id}>
          <div>
            <img className="cart-img" alt={title} src={images && images[0]} />
          </div>
          <div className="product-description">
            <h3>{title}</h3>
           {size && <p>{size} </p>}
            <p>Price:₦{numberWithCommas(discounted_price)}</p>
          </div>
          <div className="button-section">
            <div className="count-btn-container">
              <button
                disabled={cartLoading}
                className="counter-btn"
                onClick={() => cartCountHandler({ title, _id, qty, type: "decrement", productId: prodId, size })}
              >
                -
              </button>
              <span>{qty}</span>
              <button
                disabled={cartLoading}
                className="counter-btn"
                onClick={() => cartCountHandler({ title, _id, qty, type: "increment", productId: prodId , size })}
              >
                +
              </button>
            </div>
            <div className="secondary-btn-section">
              <MdDelete
                size={25}
                onClick={() => removeFromCartHandler({ _id, title })}
              />

              {!isProductInWishlist(prodId) ? (
                <AiOutlineHeart
                  size={25}
                  onClick={() => wishlistHandler({productId: prodId,title})}
                />
              ) : (
                <AiFillHeart
                  size={25}
                  onClick={() => wishlistHandler({productId: prodId,title})}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
