import "./PrivacyPolicy.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
     <Helmet>
        <title>360gadgetsafrica - Privacy Policy</title>
        <meta name="Your satisfaction is guaranteed when purchasing from 360gadgetsafrica" />
      </Helmet>
    <div className="policy-container">
      <div className="policy-heading">
        <h2 >Privacy Policy</h2>
        <h3 >Last updated: August 7, 2024</h3>
      </div>
      <div className="policy-desc">
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
        <p>Your satisfaction is guaranteed when purchasing from 360gadgetsafrica</p><br />
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.</p>
      </div>
      <div className="policy-heading">
        <h3>Interpretation</h3>
      </div>
      <div className="policy-desc">
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      </div>
      <div className="policy-heading">
        <h3>Definitions</h3>
      </div>
      <div className="policy-desc">
        <ul>
          <li>
            <b>Account </b> means a unique account created for You to access our Service or parts of our Service.
          </li>
          <li>
            <b>Company </b>(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to 360Gadgetsafrica
          </li>
          <li>
            <b>Cookies </b>are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
          </li>
          <li>
            <b>Country </b>refers to: Nigeria
          </li>
          <li>

            <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <b>Personal Data</b>  is any information that relates to an identified or identifiable individual.

          </li>
          <li>
            <b>Service</b> refers to the Application or the Website or both.
          </li>
          <li>
            <b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.

          </li>
          <li>
            <b>Third-party Social Media Service</b> refers to any website or any social network website through which a User can log in or create an account to use the Service.
          </li>
          <li>

            <b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <b>Website</b> refers to 360gadgetsafrica, accessible from www.360gadgetsafrica.com
          </li>
          <li>

            <b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </div>
      <div className="policy-heading">
        <h3>Collecting and Using Your Personal Data</h3>
      </div>
      <div className="policy-desc">
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>

        <ul>
          <li>
            Email address
          </li>
          <li>
            First name and last name
          </li>
          <li>
            Phone number
          </li>
          <li>
            Address, State, Province, ZIP/Postal code, City
          </li>
          <li>
            Usage Data
          </li>
        </ul>

      </div>
      <div className="policy-heading">
        <h3>Usage Data</h3>
      </div>
      <div className="policy-desc">

        <p>Usage Data is collected automatically when using the Service.</p>

        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>

        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

      </div>
      <div className="policy-heading">
        <h3>Use of Your Personal Data</h3>
      </div>
      <div className="policy-desc">
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <b>To provide and maintain our Service,</b> including to monitor the usage of our Service.
          </li>
          <li>
            <b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
          </li>
          <li>
            <b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
          </li>
          <li>
            <b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
          </li>
          <li>
            <b>To provide You</b> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
          </li>
          <li>
            <b>To manage Your requests:</b> To attend and manage Your requests to Us.
          </li>
          <li>
            <b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
          </li>
          <li>
           <b> For google authentication purposes:</b> 360gadgetsafrica use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.
          </li>
          <li>
            <b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
          </li>
        </ul>
      </div>
      <div className="policy-heading">
        <h3>Retention of Your Personal Data</h3>
      </div>
      <div className="policy-desc">
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

        <h2>Transfer of Your Personal Data</h2>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>

        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>

        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

      </div>
      <br />
      <br />
      <button
        onClick={() => navigate(-1)}
        className="explore-btn"
      >
        Back 
      </button>
    </div>
    <Footer />

    </>
  );
};
