import { v4 as uuid } from "uuid";
import { formatDate } from "../utils/authUtils.js";

export const users = [
  {
    _id: uuid(),
    firstName: "Aniket",
    lastName: "Saini",
    email: "testacct@gmail.com",
    password: "testacct",
    createdAt: formatDate(),
    updatedAt: formatDate(),
  },
];
