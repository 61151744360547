import "./OrderSummary.css";
import React, { useState } from "react";
import { CartProductsSummary } from "../CartProductsSummary/CartProductsSummary";
import { BillingSummary } from "../BillingSummary/BillingSummary";
import { DeliveryAddress } from "../DeliveryAddress/DeliveryAddress";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { Shipping } from "../../../Cart/components/Coupons/Shipping.jsx";

export const OrderSummary = () => {
  const [deliveryMethod, setDeliveryMethod] = useState(  {
    id: 1,
    deliveryType: "Motor park pickup",
    description: "Use this option for motor park pickup",
    amount: 3000,
  });
  const { userDataState } = useUserData();
  return (
    <div className="order-details-container">
      <CartProductsSummary />
         <Shipping
              deliveryMethod={deliveryMethod}
              setDeliveryMethod={setDeliveryMethod}
            />
      <BillingSummary deliveryMethod={deliveryMethod}/>
      {userDataState.orderDetails.orderAddress ? (
        <DeliveryAddress deliveryMethod={deliveryMethod}/>
      ) : (
        <div className="no-address">
          Please provide/select an address to proceed!
        </div>
      )}
    </div>
  );
};
