import AxiosCall from "../../controller/axios";

export const getProducts = async (filter = '') => {
    return AxiosCall({
        method: "GET",
        path: `api/products?${filter}`,
    })
};

export const addProduct = async (product) => {
    return AxiosCall({
        method: "POST",
        path: "api/products",
        data: product,
    })
};

export const updateProduct = async (product) => {
    return AxiosCall({
        method: "PATCH",
        path: "api/products",
        data: product,
    })
};


export const removeProduct = async (productId) => {
    return AxiosCall({
        method: "DELETE",
        path: `api/products/${productId}`,
    })
};

export const swapProductRequest = async (obj) => {
    return AxiosCall({
        method: "POST",
        path: `api/swap`,
        data: obj
    })
}
export const confirmAvailability = async (product) => {
    return AxiosCall({
        method: "POST",
        path: "api/products/availability-requests",
        data: product,
    })
};

export const getCategories = async () => {
    return AxiosCall({
        method: "GET",
        path: `api/categories`,
    })
};

export const createCategory = async (categoryObj) => {
    return AxiosCall({
        method: "POST",
        path: "api/categories",
        data: categoryObj,
    })
};

export const updateCategory = async (obj) => {
    return AxiosCall({
        method: "PATCH",
        path: `api/categories`,
        data: obj
    })
};

export const getPromo = async () => {
    return AxiosCall({
        method: "GET",
        path: `api/promo`,
    })
};