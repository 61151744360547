import {useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataProvider.js";
import { useUserData } from "../../contexts/UserDataProvider.js";
import "./Checkout.css";
import { AddressSection } from "./components/AddressSection/AddressSection";
import { OrderSummary } from "./components/OrderSummary/OrderSummary";
import { gaTrackEvent } from '../../utils/analytics.js';
import { Helmet } from 'react-helmet';

export const Checkout = () => {
  const { userDataState } = useUserData();
  const navigate = useNavigate();
  const { loading } = useData();
  useEffect(()=> {
    gaTrackEvent({type: "begin_checkout", category: "Ecommerce", label: "begin_checkout"})
  }, [])
  return (
    <>
     <Helmet>
        <title>360gadgetsafrica - Checkout</title>
        <meta name="Shop your favourite gadgets and accessories" />
      </Helmet>
    {!loading &&
    (userDataState.cartProducts.length ? (
      <div>
        <h1 className="page-heading">Checkout!</h1>
        <div className="checkout-container">
          <AddressSection />
          <OrderSummary />
        </div>
      </div>
    ) : (
      <div className="no-items-container">
        <h2 className="page-heading">No items in your cart to Checkout!</h2>
        <button
          className="explore-btn"
          onClick={() => navigate("/product-listing")}
        >
          Explore
        </button>
      </div>
    ))}
    </>
  )
};
