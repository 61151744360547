import React from "react";
import { useData } from "../../../../contexts/DataProvider.js";
import { Link } from "react-router-dom";
import "./CategoriesSection.css";
import useUpdateSearchParam from "../../../ProductListing/components/SearchParams.jsx";

export const CategoriesSection = () => {
  const updateSearchParam = useUpdateSearchParam();
  const { state, dispatch } = useData();
  return (
    <div>
      <h1 className="categories-heading">Shop By Categories</h1>
      <div className="categories-container">
        {state.allCategories.map(({ _id, title, slug, image }) => (
          <Link
         
            to={"/product-listing?categoryId=" + _id}
            className="category-card"
            key={_id}
          >
            <h3>{title}</h3>
            <div className="img-cont">
              <img src={image} alt={title} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
