import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { removeAddressService } from "../services/address-services/removeAddressService.js";
import { useAuth } from "./AuthProvider.js";
import { useUserData } from "./UserDataProvider.js";
import { getAddress, removeAddress } from "../services/address-services/index.js";

const AddressContext = createContext();

export function AddressProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("false");
  const { dispatch, userDataState } = useUserData();
  const [addressForm, setAddressForm] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
  });
  const { auth } = useAuth();

  const [editAddressIndex, setEditAddressIndex] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  useEffect(() => {
    getAddressList()
  }, [])
  


  const deleteAddress = async (address) => {
    try {
      setLoading(true);
      setError("");
      const response = await removeAddress(address);
        setLoading(false);
        toast.success(`${address.name}'s address deleted successfully!`);
        dispatch({ type: "SET_ADDRESS", payload:  userDataState.addressList?.filter((item)=> item._id != address._id) });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const getAddressList =  async () => {
    try {
      setLoading(true);
      const response = await getAddress();
        setLoading(false);
        dispatch({
          type: "SET_ADDRESS",
          payload: response.data,
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  } 

  return (
    <AddressContext.Provider
      value={{
        editAddressIndex,
        setEditAddressIndex,
        addressForm,
        setAddressForm,
        isAddressModalOpen,
        setIsAddressModalOpen,
        isEdit,
        setIsEdit,
        deleteAddress,
        getAddressList
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export const useAddress = () => useContext(AddressContext);
