import "./AddFundModal.css";
import React from "react";

import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { gaTrackEvent } from "../../utils/analytics";
import { RiFileCopyFill } from "react-icons/ri";
import { useWallet } from "../../contexts/WalletProvider";
import { calculateFee, numberWithCommas } from "../../utils";
import { PulseLoader } from "react-spinners";
import { useData } from "../../contexts/DataProvider";

export const WithDrawFundsModal = ({ isModalOpen, setIsModalOpen }) => {
  const { setWithdrawalForm, withdrawalForm, bankList, withdrawFunds, verifyAccountLoading, transLoading , isWithdrawVisible, setWithdrawIsVisible , wallet} = useWallet()
  const { state : {promo}  } = useData();

 
  const handleInputChange = (e) => {
    var { name, value } = e.target
    if(name == 'bank')   {
      name = JSON.parse(value)
     return setWithdrawalForm({ ...withdrawalForm, bankCode: name.code, bankName: name.name, accountName: '' })
    }

    if(name == 'amount') return setWithdrawalForm(prev => ({
      ...prev,
      [name]: value,
    }))

    setWithdrawalForm(prev => ({
      ...prev,
      [name]: value,
       accountName: ''
    }))
  }
  const submit = (e) =>{
e.preventDefault()
withdrawFunds()
  }
  return (
    isModalOpen &&
    <div className="funding-modal-container">
      <div className="funding-input-container">
        <div className="funding-content">

          <div className="funding-details">
            <h2>Fund Wallet</h2>
            {/* <p>Bank Transfer</p> */}

            <form className="form-container" onSubmit={submit}>
            <div className="form-group">
                <label htmlFor="phone">
                  Amount <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={withdrawalForm.amount}
                  onChange={handleInputChange}
                  placeholder='Please enter the amount'
                  required
                  // max={walletBalance}
                  // min={walletBalance}
                />
              {/* <small>Balance: ₦{numberWithCommas(wallet?.balance)}. Withdrawal fee ₦100</small> */}
              {withdrawalForm?.amount ? <small>Withdrawal Fee (₦{calculateFee(promo.withdrawalFee||1.7,withdrawalForm?.amount)})</small> : null}
              </div>
            <div className="form-group">
                <label htmlFor="phone">
                  Select Bank <span className="required">*</span>
                </label>
                <select
                  type="text"
                  id="bank"
                  name="bank"
                  onChange={handleInputChange}
                  // value={withdrawalForm.bankName} 
                  required
                >
                  <option >Please select a bank</option>
                  {bankList.map(d => <option value={JSON.stringify(d)}>{d.name}</option> 
                  )}
                </select>

              </div>
              <div className="form-group">
                <label htmlFor="phone">
                  Account Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="accountNumber"
                  value={withdrawalForm.accountNumber} 
                  onChange={handleInputChange}
                  // placeholder='Please enter the mobile number'
                  required
                />
               
         <div>
         <small>{verifyAccountLoading ?  <PulseLoader color='#424242' size={12} style={{position: "relative", top: '5px'}} /> : null} {withdrawalForm?.accountName} </small>
         </div>
            </div>

            <div className="download-btns">
              <button
                className="explore-btn"
                type="submit"
                disabled={!withdrawalForm.amount || !withdrawalForm.bankCode || !withdrawalForm.accountName || !withdrawalForm.accountNumber || transLoading}
                style={{ marginRight: '.3em' }}
                // onClick={() => window.location.reload()}
              >
                {transLoading ?  <PulseLoader color='#fff' size={5} style={{position: "relative"}} /> : null}
                <span>Continue</span>
              </button>
            </div>
            </form>
            <br />
          
          </div>
          <div
            className="cross-tab-icon cross-tab-icon-mobile"
            onClick={() => setIsModalOpen(false)}
          >
            <RxCross2 color={"rgb(106, 106, 65)"} size={25} />
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};
