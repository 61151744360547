
export const initialUserData = {
  cartProducts: [],
  wishlistProducts: [],
  addressList: [],
  orders: [],
  orderDetails: {
    cartItemsTotal: "",
    cartItemsDiscountTotal: "",
    couponDiscountTotal: "",
    orderAddress: "",
    orderId: "",
    deliveryMethod: ""
  },
};


export const userDataReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CART": {
      return { ...state, cartProducts: action.payload };
    }
    case "ADD_TO_CART": {
      return { ...state, cartProducts: [...state.cartProducts, action.payload] };
    }

    case "SET_ADDRESS": {
      return { ...state, addressList:  action.payload };
    }

    case "LOAD_WISHLISTS": {
      return { ...state, wishlistProducts: action.payload };
    }

    case "ADD_TO_WISHLISTS": {
      return { ...state, wishlistProducts: [...state.wishlistProducts, action.payload] };
    }

    case "SET_ORDER": {
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload,
        },
      };
    }

    case "SET_ORDERS": {
      return { ...state, orders: [...state.orders, { ...action.payload }] };
    }
    case "LOAD_ORDERS": {
      return { ...state, orders: action.payload  };
    }
    default:
      return state;
  }
};
