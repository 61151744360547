import AxiosCall from "../../controller/axios";


export const getAddress = async () => {
    return AxiosCall({
        method: "GET",
        path: "api/address",
    })
};

export const addAddress = async (address) => {
    return AxiosCall({
        method: "POST",
        path: "api/address",
        data: address,
    })
};
export const updateAddress = async (params) => {
    return AxiosCall({
        method: "PATCH",
        path: "api/address",
        data: params,
    })
};


export const removeAddress = async (addressId) => {
    return AxiosCall({
        method: "DELETE",
        path: "api/address/"+addressId,
    })
};

