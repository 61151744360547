import React, { useEffect , useMemo} from "react";

import "./ProductListing.css";
import { Filter } from "./components/Filter/Filter";
import { ProductListingSection } from "./components/ProductListingSection/ProductListingSection";
import { useData } from "../../contexts/DataProvider.js";
import { Footer } from "../../components/Footer/Footer.jsx";
import { useSearchParams } from "react-router-dom";

export const ProductListing = () => {
  const { loading, dispatch, getAllProducts, state: { allProductsFromApi: { nextPage, page }, search_params } } = useData();
  const [searchParams] = useSearchParams();


  useEffect(() => {
    if (search_params != searchParams.toString()) {
      dispatch({
        type: "UPDATE_SEARCH_PARAMS",
        payload: searchParams.toString()
      })
      getAllProducts()
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // This enables the smooth scrolling effect
      })
    } else if(page == null) getAllProducts()
  }, [searchParams.toString()])

  // searchParams.toString()
  return (
    <>
      <div className="page-container">
        <Filter className="filters" />
        <div className="x-product-div">
          {(!loading || nextPage > 1) && (
            <ProductListingSection className="products-container" />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
