import React, { useEffect } from 'react';
import './WalletDashboard.css';
import AxiosCall from '../../../controller/axios';
import { getWallet } from '../../../services/wallet';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';

function WalletDashboard() {
  const navigate = useNavigate();
  const { dataPlan } = useAuth();

  return (
    <>
      <div className="x-wallet-payment-methods">
        <h2 className="payment-methods__title">Shop for Cheap Data</h2>
      </div>
      <div className='x-plans'>
        {dataPlan.map(d => (
          <div className='x-plan-box' onClick={() => {
          }}>
            <div className='x-plan-type'>
              {d.network} <br /> {d.planType}
            </div>
            <div className='x-plan-content'>
              <div className='x-plan-name'>
                {d?.planName}
              </div>
              <div className='x-plan-duration'>
                {d?.duration}
              </div>
              <div className='x-plan-amount'>
                {d?.amount}
              </div>
            </div>
          </div>))}
      </div>
      {/* <div className="payment-methods">
        <h2 className="payment-methods__title">Other Services</h2>
        <div className="payment-method" onClick={()=>navigate('/profile/buy-data')}>
          <div className="payment-method__info">
            <h3 className="payment-method__name">Buy Data</h3>
            <p className="payment-method__number">Enjoy cheap data as low as N200</p>
          </div>
          <span className="payment-method__chevron">›</span>
        </div>
        <div className="payment-method">
          <div className="payment-method__info">
            <h3 className="payment-method__name">Buy Airtime</h3>
            <p className="payment-method__number">Enjoy cheap airtime</p>
          </div>
          <span className="payment-method__chevron">›</span>
        </div>
        <div className="payment-method">
          <div className="payment-method__info">
            <h3 className="payment-method__name">Recharge Prepaid</h3>
            <p className="payment-method__number">Enjoy cheap reload</p>
          </div>
          <span className="payment-method__chevron">›</span>
        </div>
      </div>  */}
    </>
  );
}

export default WalletDashboard;
