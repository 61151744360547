
import { useEffect, useState } from 'react'
import './BuyData.css'
import { detectNetwork } from '../../../utils'
import { RiArrowLeftLine, RiCheckboxCircleFill, RiLoader2Fill } from 'react-icons/ri'
import { useAuth } from '../../../contexts/AuthProvider';
import { LoadingIcon } from 'yet-another-react-lightbox';
import {   PulseLoader } from 'react-spinners';
import { useWallet } from '../../../contexts/WalletProvider';
import { Logout } from '../../auth/Logout/Logout';

export default function BuyData() {
  const { dataPlan , buyPlan,  vtuFormData, setVtuFormData , vtuLoading} = useWallet();
  const [currentStep, setCurrentStep] = useState(1)
  const [network, setNetwork] = useState('')
 
  const handleInputChange = (e) => {
    var { name, value } = e.target
    if(name == 'plan') value = JSON.parse(value)
    setVtuFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  const handleNextStep = (e) => {
    e.preventDefault()
    if (vtuFormData.phone) {
      setCurrentStep(prev => Math.min(prev + 1, 5))
    } else alert('Please fill the form')
  }
  useEffect(() => {
    if (vtuFormData?.phone?.length > 10) {
      setNetwork(detectNetwork(vtuFormData.phone));
    }
  }, [vtuFormData.phone])
  const goBack = (e) => {
    e.preventDefault()
    setCurrentStep(currentStep - 1)
  }
  return (
    <div className='x-buydata'> 
    <div className="form-container">
      {currentStep == 1 ? <h1>Lets topup your data bundle!</h1> : <h1>Complete your purchase</h1>}

      {currentStep == 1 && <form onSubmit={handleNextStep}>
        <div className="form-group">
          <label htmlFor="phone">
            Mobile Number <span className="required">*</span>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={vtuFormData.phone}
            onChange={handleInputChange}
            placeholder='Please enter the mobile number'
            required
          />
          {network && <div className='network'> <small>{network} <RiCheckboxCircleFill color='#28a745' /></small>
          </div>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">
            Data Plan <span className="required">*</span>
          </label>
          <select
            type="text"
            id="phone"
            name="plan"
            value={JSON.stringify(vtuFormData.plan)}
            onChange={handleInputChange}
            placeholder='Please enter the mobile number'
            required
          >
            <option >Please select a plan</option>
            {dataPlan?.map(d => !network ? null : network == "Unknown" ? <option value={JSON.stringify(d)}>{d.planName} - {d.duration} - ₦{d.amount} {d.network}</option> :
              network && d.network == network ? <option value={JSON.stringify(d)}>{d.planName} - {d.duration} - ₦{d.amount}  {d.network}</option> : null
            )}
          </select>

        </div>
        <div className="form-progress">
          <div className="step-indicator">
            Step {currentStep} of 2
          </div>
          <div className="step-dots">
            {[1, 2].map((step) => (
              <div
                key={step}
                className={`step-dot ${step === currentStep ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>

        <div className='x-cta' style={{ justifyContent: "flex-end" }}>

          <button type="submit" className="next-button">
            NEXT
          </button>
        </div>
        <br />
        <h1>Checkout our pricing</h1>
        <div className='x-plans'>
          {dataPlan?.map(d => (
            <div className='x-plan-box' onClick={() => {
            }}>
              <div className='x-plan-type'>
                {d.network} <br /> {d.planType}
              </div>
              <div className='x-plan-content'>
                <div className='x-plan-name'>
                  {d?.planName}
                </div>
                <div className='x-plan-duration'>
                  {d?.duration}
                </div>
                <div className='x-plan-amount'>
                ₦{d?.amount}
                </div>
              </div>
            </div>))}
        </div>
      </form>}
      {/* {currentStep == 2 && <div>
        <div className='x-plans'>
          {dataPlan.map(d => (d.network != network &&
            <div className='x-plan-box' onClick={() => {
              setVtuFormData({ ...formData, selectedPlan: d })
              setCurrentStep(3)
            }}>
              <div className='x-plan-type'>
                {d.planType}
              </div>
              <div className='x-plan-content'>
                <div className='x-plan-name'>
                  {d?.planName}
                </div>
                <div className='x-plan-duration'>
                  {d?.duration}
                </div>
                <div className='x-plan-amount'>
                  {d?.amount}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="form-progress">
          <div className="step-indicator">
            Step {currentStep} of 3
          </div>
          <div className="step-dots">
            {[1, 2, 3].map((step) => (
              <div
                key={step}
                className={`step-dot ${step === currentStep ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className='x-cta'>
       <button  className="back-button" onClick={goBack}>
          BACK
        </button>
        <button type="submit" className="next-button">
          NEXT
        </button>
        </div>
      </div>} */}
      {currentStep == 2 && <div>
        <div className='x-plans-details'>
          <div>
            <div className='x-flex'><b>Network:</b> <span>{network}</span></div>
            <div className='x-flex'><b>Phone:</b> <span>{vtuFormData.phone}</span></div>
            <div className='x-flex'><b>Plan</b> <span>{vtuFormData?.plan?.planName}</span></div>
            <div className='x-flex'><b>Amount</b> <span>₦{vtuFormData.plan?.amount}</span></div>
          </div>
        </div>
        <div className="form-progress">
          <div className="step-indicator">
            Step {currentStep} of 2
          </div>
          <div className="step-dots">
            {[1, 2].map((step) => (
              <div
                key={step}
                className={`step-dot ${step === currentStep ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className='x-cta'>
          <button className="back-button" onClick={goBack}>
            <RiArrowLeftLine />  BACK
          </button>
          <button  disabled={vtuLoading} className="next-button" onClick={()=>{
            buyPlan(vtuFormData)
          }}>
           {vtuLoading && <PulseLoader size={5}  color='#fff'/> } <span style={{position: 'relative'}}>PURCHASE</span>
          </button>
        </div>
      </div>}
    </div>
    <Logout />
    </div>
  )
}

