export const gaTrackEvent = ({ label = '', type = '', category= '', data = {}}) => {
   try {
    if (window.gtag) {
        window.gtag('event', type, {
            'event_category': category,
            'event_label': label,
            ...data
        });
    }
   } catch (error) {
    console.log(error)
   }
};