// import "./Termsofuse.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

export const Termsofuse = () => {
  const navigate = useNavigate();
  return (
    <>
        <Helmet>
        <title>360gadgetsafrica - Terms of use</title>
        <meta name="You may not use the Services if you do not accept the Terms or are unable to be bound by the Terms" />
      </Helmet>
    <div className="policy-container">
      <div className="policy-heading">
        <h2 >Terms & Condition</h2>
        <h3 >Last updated: August 7, 2024</h3>
      </div>
      <div className="policy-desc">
        <p>These Terms of Service (the "Terms") are intended to make you aware of your legal rights and responsibilities with respect to your access to and use of the 360gadgetsafrica and any related mobile or software applications including but not limited to delivery of information via the app whether existing now or in the future that link to the Terms (collectively, the "Services")</p>
        <p>By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app</p><br />
        <p>You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions.</p>
        <p>The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to 360gadgetsafrica</p>
        <p></p>
        <p></p>
      </div>
      <div className="policy-heading">
        <h3> Acceptance of terms</h3>
      </div>
      <div className="policy-desc">
        <p>Please read these Terms carefully</p>
        <p>360gadgetsafrica is constantly evolving in order to provide the best possible experience and information to its Customers. You acknowledge and agree that the form and nature of the Services which 360gadgetsafrica provides, may require affecting certain changes in it, therefore, 360gadgetsafrica reserves the right to suspend/cancel, or discontinue any or all products or services at any time without notice, make modifications and alterations in any or all of its contents, products and services contained on the site without any prior notice.</p>
        <br />
        <p>You acknowledge and agree that if 360gadgetsafrica disables access to your account, you may be prevented from accessing the Services, your account details or any files or other content, which is contained in your account.</p>
        <p>our effort to continuously improve the 360gadgetsafrica Platform and Services, we undertake research and conduct experiments from time to time on various aspects of the Services and offerings, including our apps, websites, user interface and promotional campaigns. As a result of which, some Customers may experience features differently than others at any given time. This is for making the 360gadgetsafrica Platform better, more convenient and easy to use, improving Customer experience, enhancing the safety and security of our services and offerings and developing new services and features.</p>
        <br />
        <p>By signing up. automatically you have subscribe to our newsletters. You also have the options to unsubscribe whenever you want to by clicking the unsubscribe link in the email</p>
        <br />
        <p>You acknowledge that we are not direct sellers; we simply provide a platform for an exclusive network of suppliers. In other to ensure that we offer access to reliable, high-quality, and affordable gadgets </p>
        <br />
        <p> Kindly note that our partnered vendors do not cover water, screen and electricity damage. </p>
        <br />
        <p> UK used devices have a validity period of 1 week and brand new devices for 2 weeks. </p>
        <br />
       
        <p>By accessing or using the 360gadgetsafrica Platform, you are agreeing to these Terms and concluding a legally binding contract .</p>
        <br />
        <p>You may not use the Services if you do not accept the Terms or are unable to be bound by the Terms</p>
      <br/>
      <br />
  </div>
      <button
        onClick={() => navigate(-1)}
        className="explore-btn"
      >
        Back 
      </button>
    </div>
    <Footer />
    
    </>
  );
};
