import React, { useState } from "react";
import { useUserData } from "../../../contexts/UserDataProvider.js";
import "./Orders.css";
import { closePaymentModal, FlutterWaveButton, useFlutterwave } from 'flutterwave-react-v3';
import { addOrders, updateOrders } from "../../../services/order-services/index.js";
import toast from "react-hot-toast";
import { useAuth } from "../../../contexts/AuthProvider.js";
import { CgArrowRightO } from "react-icons/cg";
import { PaystackButton } from "react-paystack";

export const Orders = () => {
  const { auth, setCurrentPage } = useAuth();
  const { userDataState } = useUserData(); 
  const config = {
    reference: (new Date()).getTime().toString(),
    email:  auth.email || localStorage.getItem("email"),
    amount: 0, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_live_2cba6fb0d9a4cd6e4ab9a8f13408e1dc2525e77a',
  };
  const componentProps =  (_id, amount)=> ({
    ...config, amount,
    text: 'Pay',
    onSuccess: async (response) => {
      console.log(amount,  'amount')
        if (response.status == 'success') {
          response.status =  'completed'
          response.amount = amount/100
          response.transaction_id = response.transaction
          response.tx_ref = response.trxref
          try {
            const order = {
              flutterwave: {
                transaction_id: response.transaction_id,
                tx_ref: response.tx_ref,
              },
              _id
            }
            const res = await updateOrders(order)
            toast.success("Your payment was successful. you will be contacted shortly");
            setTimeout(() => {
              window.location.reload()
            }, 2000);
          } catch (error) {
            toast("An error has occured please contact us for additional support");
          }
        }
    },
    onClose: ()=>{},
});
 

  // const handleFlutterPayment =  useFlutterwave(config);
 
  const cancel = async (order) => {
    try {
      const res = await updateOrders(order)
      toast.success("Your payment was cancelled succesfully.");
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }catch (error) {
      toast("An error has occured please contact us for additional support");
    }
  
   
  }

  return !userDataState.orders?.length ? (
    <div className="orders-container">No Orders</div>
  ) : (
    <div className="orders-container">
      {userDataState.orders?.map(
        ({
          amountPaid,
          deliveryAddress,
          trackingId,
          orderedProducts,
          flutterwave,
          _id,
          status
        }) => (
          <div key={trackingId} className="ordered-items-card">
            {flutterwave?.transaction_id == flutterwave?.tx_ref && status != 'cancelled' ?
              <div className="order-id-container">
              <span>Awaiting Payment: </span>
              <small>Please complete your payment to proceed for pickup</small> 
            </div>
            :
            <div className="order-id-container">
              <span>Tracking ID: </span>
              <span>{trackingId}</span> 
            </div>}
            <div className="payment-id-container">
              <span>Status: </span>
              <span>{status || "Pending"}</span>
            </div>
            <div className="price-container">
              <span>Amount: </span>
              <span>₦{amountPaid}</span>
            </div>
              <div className="price-container">
                <span>Delivery-Address:</span>
                <span>
                  {deliveryAddress?.street} {deliveryAddress?.state}{" "}
                  {deliveryAddress?.country}
                </span>
              </div>
            {/* {flutterwave?.transaction_id == flutterwave?.tx_ref &&
              <>
                <div className="price-container">
                  <b>Pickup address:</b>
                  <span>
                    His Grace Plaza, 14 Francis Oremeji St, Ikeja, 101233, Lagos
                  </span>
                  <a href="https://maps.app.goo.gl/XteHMHUwDpzEpukc6" target="_blank" >Locate on map</a>
                </div>
              {flutterwave?.transaction_id != flutterwave?.tx_ref && <div className="price-container">
                  <b>Contact phone: +2348039938596</b>
                </div>}
              </>
            } */}
            <div className="products-container">
              {orderedProducts?.map(
                ({ productId: { images, title, discounted_price }, price, id, qty, size }) => (
                  <div className="products-card" key={id}>
                    <img src={images[0]} alt={title} />
                    <div className="description">
                      <span className="name">Name: {title}</span>
                      {size && <span className="qty">Size: {size}</span>}
                      <span className="qty">Qty: {qty}</span>
                      <span className="price">Price: ₦{price}</span>

                    </div>
                  </div>
                )
              )}
              {/* {(status != 'delivered' && status != 'cancelled' ) &&
              flutterwave?.transaction_id == flutterwave?.tx_ref &&
                <div className="payment-btn-group">
                   <div className="add-address-btn-container">
                    <PaystackButton {...componentProps(_id, amountPaid * 100)} />
                  </div>
                  <button onClick={()=>cancel({
                      flutterwave,
                      _id, status: 'cancelled'
                  })}>Cancel</button>
                </div>} */}
            </div>
          </div>
        )
      )}
    </div>
  );
};
