import "./ProductDetails.css";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useData } from "../../contexts/DataProvider.js";
import { ProductImage } from "./components/ProductImage/ProductImage";
import { ProductDescription } from "./components/ProductDescription/ProductDescription";
import { Footer } from "../../components/Footer/Footer.jsx";
import { Helmet } from 'react-helmet';
import { MoreInfo } from "./components/MoreInfo/MoreInfo.jsx";

export const ProductDetails = () => {
  const { state } = useData();
  const { productId } = useParams();
  const { loading, getProduct } = useData();
  const navigate = useNavigate()
  useEffect(() => {
    getProduct({ slug: productId })
  }, [])
  return (
    <>
      {loading ? <div className="x-height"></div> : state.product?._id ? (
        <>
          <Helmet>
            <title>{state.product?.title}</title>
            <meta name="description" content={state.product?.description} />
          </Helmet>
          <div className="products-page-container">
            <ProductImage selectedProduct={state.product} />
            <ProductDescription selectedProduct={state.product} />
          </div>
            {window.innerWidth <= 425 && <MoreInfo />}
        </>
      ) : (
        <div className="wishlist-empty-container ">
          <h2 className="page-heading">This product is not available!</h2>
          <button
            className="explore-btn"
            onClick={() => navigate("/product-listing")}
          >
            Explore
          </button>
        </div>
      )}

      <Footer />
    </>
  )
};
