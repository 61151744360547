import React, { useState, useEffect } from 'react';

export default function CountdownTimer({ dateString, onTimeout }) {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isValid, setIsValid] = useState(true);
  var dateString = new Date(dateString);
  dateString.setHours(dateString.getHours() + 2); // Add 2 hours to the current date
  useEffect(() => {
    const targetDate = new Date(dateString).getTime();

    if (isNaN(targetDate)) {
      setIsValid(false);
      return;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        if (onTimeout && typeof onTimeout === 'function') {
          onTimeout();
        }
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [dateString, onTimeout]);

  if (!isValid) {
    return (
      <div style={styles.container}>
        <span style={styles.errorText}>Invalid date string provided</span>
      </div>
    );
  }

  return (
    <div style={styles.container}>
        <CountdownUnit value={countdown.hours} label="Hours" />
        <span color='#fff' fontSize={12}>:</span>
        <CountdownUnit value={countdown.minutes} label="Minutes" />
        <span color='#fff' fontSize={12} >:</span>
        <CountdownUnit value={countdown.seconds} label="Seconds" />
    </div>
  );
}
function CountdownUnit({ value, label }) {
  return (
      <span style={styles.unitValue}> {value.toString().padStart(2, '0')} </span>
  );
}


const styles =  {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
    // padding: 20,
  },
  unitValue: {
    fontSize: 12,
  },
  unitLabel: {
    fontSize: 14,
    color: '#666',
  },
  errorText: {
    color: 'red',
    fontSize: 16,
  },
}