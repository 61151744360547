import AxiosCall from "../../controller/axios";


export const getCarts = async () => {
    return AxiosCall({
        method: "GET",
        path: "api/carts",
    })
};

export const addToCarts = async (product) => {
    return AxiosCall({
        method: "POST",
        path: "api/carts",
        data: product,
    })
};
export const updateCarts = async (params) => {
    return AxiosCall({
        method: "PATCH",
        path: "api/carts",
        data: params,
    })
};


export const removeFromCarts = async (cartId) => {
    return AxiosCall({
        method: "DELETE",
        path: "api/carts/"+cartId,
    })
};

