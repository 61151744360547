import AxiosCall from "../../controller/axios";


export const getOrders = async () => {
    return AxiosCall({
        method: "GET",
        path: "api/orders",
    })
};

export const addOrders = async (orders) => {
    return AxiosCall({
        method: "POST",
        path: "api/orders",
        data: orders,
    })
};

export const updateOrders = async (order) => {
    return AxiosCall({
        method: "PATCH",
        path: "api/orders",
        data: order,
    })
};