// import "./ReturnPolicy.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

export const ReturnPolicy = () => {
  const navigate = useNavigate();
  return (
    <>

<Helmet>
        <title>360gadgetsafrica - Return and Exchange Policy</title>
        <meta name="Your satisfaction is guaranteed when purchasing from 360gadgetsafrica" />
      </Helmet>
    <div className="policy-container">
      <div className="policy-heading">
        <h2 >Return and Exchange Policy</h2>
        <h3 >Your satisfaction is guaranteed.</h3>
      </div>
      <div className="policy-desc">
        <p>Your satisfaction is guaranteed when purchasing from 360gadgetsafrica</p><br />
        <p> If you are not satisfied with your purchase, you may request a return by contacting our customer support if eligible. The return conditions apply to all products purchased at 360gadgetsafrica and proof of purchase must be presented when making your return request, within the return period.</p>
      </div>
      <div className="policy-heading">
        <h3>Returns & Exchange Eligibility:</h3>
      </div>
      <div className="policy-desc">
        <ul>
          <li> Products must be unused and in the same condition that you received it.</li>
          <li> Products must be in the original, undamaged packaging, including any manuals, documentation, registration, and accessories. </li>
          <li> Products must be received within 24hours from the date you received it </li>
        </ul>
        <br />
        <p>Products that do not meet our eligibility criteria may be refused and returned to you. We reserve the right to refuse discretion returns where multiple styles and sizes have been purchased.</p>
        <p>Please ensure all returned products are well packaged for transit. We cannot take responsibility for items lost or damaged in transit.</p>


      </div>
      <div className="policy-heading">
        <h3>Under our Returns & Exchange Policy we offer the following options:</h3>
      </div>
      <div className="policy-desc">
        <ul>
          {/* <li> Refund to the original payment method.</li> */}
          <li> Exchange for an alternative product that is identically priced. </li>
          {/* <li> Products must be received within 24hours from the date you received it </li> */}
        </ul>
      </div>
      {/* <div className="policy-heading">
        <h3>Refunds</h3>
      </div>
      <div className="policy-desc">
        <p>Once your return is approved, a refund will be issued to your original payment method. Please allow 15 - 20 business days for the refund to appear on your account.</p>
      </div> */}
      <div className="policy-heading">
        <h3>Exchange</h3>
      </div>
      <div className="policy-desc">
        <p>Once your return is approved, your exchange order will be processed and shipped within 1 - 2days. A confirmation email will be sent to confirm the order and tracking will be provided once shipped. Please contact support if you have any questions.</p>
        <br />
        <p>Email our support at:  support@360gadgets.com </p>
        <p>Call us on: +2348039938586 </p>
      </div>
      <br />

      <br />
      <button
        onClick={() => navigate(-1)}
        className="explore-btn"
      >
        Back 
      </button>

    </div>
    <Footer />

    </>
  );
};
