import "./Swap.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { useUserData } from "../../contexts/UserDataProvider";
import { Helmet } from "react-helmet";
import { CgAirplane } from "react-icons/cg";
import { RiLightbulbFlashFill } from "react-icons/ri";
 
export const Swap = () => {
  const navigate = useNavigate();
  const {
    swapLoading,
    sendSwapRequest,
  } = useUserData();
  
  const [formData, setFormData] = useState({
    deviceName: "",
    model: "",
    condition: "",
    preferreDeviceName: "",
    preferreDeviceModel: ""
  })

  const handleInput = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendSwapRequest({...formData, email: window.localStorage.getItem("email")})
  }

  return (
    <>
       <Helmet>
        <title>360gadgetsafrica - Swap Your Phone</title>
        <meta name="Swap Your Phone: Upgrade with Ease." />
      </Helmet>
      <div className="swap-container">
        <div className="policy-container" style={{minHeight: '300px'}}>
          <div className="policy-heading" >
            <h2>⏳ Hold Tight! </h2>
            {/* <h3>Swap Your Phone: Upgrade with Ease.</h3> */}
          </div>
          <div className="policy-desc" >
            <p> This page is under construction. Check back soon !
            #UnderConstruction #UpgradesOnTheWay #BetterExperience</p>
          </div>
          {/* <div className="policy-desc">
            <p> Are you ready for a new phone but don’t want to deal with the hassle of selling your old one? Swapping your phone is a smart and convenient way to upgrade to the latest device while getting value for your old phone.</p>
          </div>
          <div className="policy-heading">
            <h3>How It Works</h3>
          </div>
          <div className="policy-desc">
            <p>Swapping your phone with us is easy. Follow these simple steps to get started:</p>
            <ul>
              <li><b>Get an Instant Quote : </b>
                Use our form below to get an estimate of your phone’s value from our support email</li>
              <li><b>Inspection : </b>
                Once you accept the offer from your Email, the next step is inspection and review.</li>
              <li><b>Receive Your Credit : </b>
                After inspection and acceptance, You’ll then receive credit towards your new phone purchase on your dashboard </li>
              <li><b>Choose Your Preferred Phone : </b>
                Browse our extensive range of the latest phones. Apply your credit at checkout and enjoy your new device.</li>
            </ul>
          </div>
          <div className="policy-heading">
            <h3><b>Get Started Today!</b></h3>
          </div>
          <div className="policy-desc"> 
              <form onSubmit={handleSubmit}>
            <div className="name-container">
              <label htmlFor="device">Your Device Name</label>
              <input
              onChange={handleInput} 
                id="device"
                placeholder="E.g samsung, apple, google pixel"
                type="text"
                name="deviceName"
                required
              />
              <label htmlFor="device">Your Device Model</label>
              <input
            
                name="model"
                required
              onChange={handleInput} 
                placeholder="E.g iphone 12, Galaxy S22"
                type="text"
              />
              <label htmlFor="device">Your Device Condition</label>
              <textarea
            
                name="condition"
                required
          onChange={handleInput}  
                placeholder="E.g iphone 12, Galaxy S22"
             
              />
              <br />
              <label htmlFor="device">Preferred Device Name</label>
              <input
            
            required
          onChange={handleInput} 
                name="preferreDeviceName" 
                placeholder="E.g samsung, apple, google pixel"
                type="text"
              />
              <label htmlFor="device">Preferred Device Model</label>
              <input
                name="preferreDeviceModel"
            
            required
          onChange={handleInput}  
                placeholder="E.g iphone 12, Galaxy S22"
                type="text"
              /> 
            <div>
            <br />
              <button
                className="explore-btn"
              >
                Get a quote
              </button>
            </div>
            </div>
              </form>
          </div>
         */}
        </div>
      </div>
      <Footer />
    </>
  );
};
