import "./CartProductsSummary.css";

import React from "react";
import { useUserData } from "../../../../contexts/UserDataProvider.js";

export const CartProductsSummary = () => {
  const { userDataState } = useUserData();
  return (
    <div className="product-details-container">
      <h2>In Your Bag</h2>
      <div className="ordered-products-container">
        {userDataState.cartProducts?.map(
          ({ productId: {images, title, discounted_price }, _id, qty}) => (
            <div key={_id} className="ordered-product-card">
              <img src={images[0]} alt={title} />
              <div style={{padding: '0 0em 0 1em'}}>
                <span>{title} - </span>
                <span>{qty}qty</span>
              <span> - ₦{discounted_price}</span>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
