import React from "react";
import "./BillingSummary.css";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { numberWithCommas } from "../../../../utils/index.js";

export const BillingSummary = ({deliveryMethod}) => {
  const { userDataState } = useUserData();
  return (
    <div className="billing-container">
      <div className="price-details-container">
        <div>
          <span className="subtotal">Subtotal</span>
          <span>₦{numberWithCommas(userDataState.orderDetails?.cartItemsDiscountTotal)}</span>
        </div>
{/* 
        <div>
          <span className="subtotal">Discount</span>
          <span>
          ₦
            {numberWithCommas((
              userDataState.orderDetails?.cartItemsTotal -
              userDataState.orderDetails?.cartItemsDiscountTotal
            ).toFixed(2))}
          </span>
        </div> */}

        <div>
          <span>Delivery</span>
          <span style={{textAlign: 'right'}}>₦{deliveryMethod?.amount}</span>
        </div>
        <div>
          <span>Total</span>
          <span>₦{numberWithCommas(parseInt(userDataState.orderDetails?.cartItemsDiscountTotal) +  deliveryMethod?.amount)}</span>
        </div>
      </div>
    </div>
  );
};
