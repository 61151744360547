import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { loginService, sendOtp, verifyOtp, refreshTokenService } from "../services/auth-services/loginService";
import { gaTrackEvent } from "../utils/analytics";
import { fetchBanks, fetchDataPlan, getWallet, purchaseData, verifyBank } from "../services/wallet";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("profile");

  const [otpCode, setOtpCode] = useState("")
    const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [signupCredential, setSignupCredential] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    referralCode: ""
  });

  const [auth, setAuth] = useState({});
  useEffect(() => {
    load()
    refreshAcct()
  }, [])

  const setUserStorage = (user,token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user)); 
  }
  const loginHandler = async ({ e, email, password, oauthToken, appleToken }) => {
    try {
      e?.preventDefault();
      setLoginLoading(true);
      setError("");
      setLoginCredential({ email, password, });
      const response = await loginService({ email, password, oauthToken, appleToken });
      const { user, token } = response.data
      setLoginLoading(false);
      // (() => Toast.show({text1: `Welcome back, ${user.firstName}!`, type: 'success'}))()
      setAuth({
        token,
        isAuth: true,
        ...user
      });
      setUserStorage(user,token)
      setLoginCredential({ email: "", password: "" });
      user?.vendorId && localStorage.setItem("vendorId", JSON.stringify(user?.vendorId));
     
      if(user?.verificationCode){
       setTimeout(() => {
        sendOtpMail()
        window.location.assign("/verification");
       }, 1000);
      } else window.location.assign("/profile");

      // window.location.assign(location?.state?.from.pathname || "/");
    } catch (error) {
      setLoginLoading(false);
      setError(error.response?.data?.errors[0]);
    } finally {
      setLoginLoading(false);
    }
  };

  // const signupHandler = async () => {
  //   try {
  //     setSignUpLoading(true);
  //     setError("");
  //     const response = await signupService({
  //       email: signupCredential.email,
  //       password: signupCredential.password,
  //       firstName: signupCredential.firstName?.split(' ')[0],
  //       lastName: signupCredential.firstName?.split(' ')[1],
  //       referralCode: signupCredential.referralCode
  //     }
  //     );
  //     const { user, token } = response.data
  //     setSignUpLoading(false);
   

  //     setAuth({
  //       ...user,
  //       token,
  //       isAuth: true,
  //     });

  //     setUserStorage(user,token)

  //     if(user?.verificationCode){
  //       location.assign("/verification");
  //     } else location.assign("/profile");

  //   } catch (error) {
  //     setSignUpLoading(false);
  //     setError(error.response?.data?.errors[0]);
  //   } finally {
  //     setSignUpLoading(false);
  //   }
  // };
  const refreshAcct = async () =>{
    try {
     const res = await refreshTokenService();
     if(res?.data?.token){
       setUserStorage(res?.data?.user, res?.data?.token)
       load()
     }
    } catch (error) {
    }
   }
  const load = async () => {
    const encodedToken =  localStorage.getItem("token");
    const data = localStorage.getItem("user");
    if (encodedToken) {
      var user = JSON.parse(data)
      setAuth({ token: encodedToken, isAuth: true, ...user})
    } else {
      setAuth({ token: "", isAuth: false })
    }
  }

  const verifyOtpCode = async () =>{
    setOtpLoading(true)
   try {
    const res = await verifyOtp({otp: otpCode, email: auth.email});
    refreshAcct()
    setOtpCode('')
    
     toast.success(`Verification was successful`)
   setTimeout(() => {
    window.location.assign("/profile");
   }, 1000);
   } catch (error) {
       toast.error( error?.response?.data?.errors[0] || `An error has occured. please try again later`)
   }
   setOtpLoading(false)
  }

  const sendOtpMail = async () =>{
    try {
     const res = await sendOtp({ email: auth.email});
    toast.success(`OTP code was sent successful`)
    } catch (error) {
     toast.error(error?.response?.data?.errors[0] || `An error has occured. please try again later`)
    }
   }
 
  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loginHandler,
        error,
        setError,
        loginLoading,
        setLoginLoading,
        loginCredential,
        setLoginCredential,
        signupCredential,
        setSignupCredential,
        signUpLoading,
        setCurrentPage,
        currentPage,
        setIsLoginModalOpen,
        isLoginModalOpen,
        setOtpCode,
        otpCode,
        verifyOtpCode,
        sendOtpMail,
        otpLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
