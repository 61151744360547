import "../PrivacyPolicy/PrivacyPolicy";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

export const ShippingPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>360gadgetsafrica - Shipping Policy</title>
        <meta name="Your satisfaction is guaranteed when purchasing from 360gadgetsafrica" />
      </Helmet>
      <div className="policy-container">
        <div className="policy-heading">
          <h2 >Shipping Policy</h2>
          <h3 >Last updated: August 7, 2024</h3>
        </div>
        <div className="policy-desc">
          <p>At 360GadgetsAfrica, we are committed to delivering your gadgets and accessories in a timely and
            secure manner. </p>
          <p>Please review our shipping policy to understand how we handle orders, shipping, and
            delivery.</p><br />
        </div>
        <div className="policy-heading">
          <h3>Shipping Location</h3>
        </div>
        <div className="policy-desc">
          <p>We currently ship to all regions within Nigeria, including major cities and remote areas. International
            shipping is not available at the moment, but we are working on expanding our service in the future.</p>
        </div>
        <div className="policy-heading">
          <h3>Shipping rates</h3>
        </div>
        <div className="policy-desc">
          <p>Shipping rates are calculated based on your delivery location of your package. The shipping cost will
            be displayed at checkout before you confirm your order.</p>
        </div>
        <div className="policy-heading">
          <h3>Processing Time and Delivery time:</h3>
        </div>
        <div className="policy-desc">
          <p>Orders are processed within 1-2 days from the day the purchased was made. Delivery times may vary depending on your location. Please note that unforeseen delays, such as weather conditions or courier issues, may affect delivery
            times. We will notify you in case of any significant delays.</p>
        </div>
        <div className="policy-heading">
          <h3> Order Tracking:</h3>
        </div>
        <div className="policy-desc">
          <p>Once your order has been shipped, you will receive a confirmation email with tracking information.
            You can monitor the status of your shipment using the provided tracking number by contacting
            support.</p>
        </div>
        <div className="policy-heading">
          <h3>Shipping Restrictions:</h3>
        </div>
        <div className="policy-desc">
          We do not ship to P.O. Boxes or certain remote areas where couriers are unavailable. In such cases,
          we will contact you to arrange an alternative shipping method.
        </div>
        <div className="policy-heading">
          <h3> Incorrect Address:</h3>
        </div>
        <div className="policy-desc">
          Please ensure that your shipping address is correct before finalizing your order. 360GadgetsAfrica is
          not responsible for orders shipped to incorrect or incomplete addresses provided by the customer. If
          you realize that the shipping address is incorrect, please contact us immediately at
          support@360gadetsafrica.com to update your details
        </div>
        <div className="policy-heading">
          <h3> International Shipping:</h3>
        </div>
        <div className="policy-desc">
          Currently, we only ship within Nigeria. International shipping will be available soon, and we will update
          our policy accordingly.
        </div>
        <br />
        <br />
        <button
          onClick={() => navigate(-1)}
          className="explore-btn"
        >
          Back
        </button>
      </div>
      <Footer />

    </>
  );
};
