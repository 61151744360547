
export const GOOGLE_CLIENT_ID = '830618780069-1eoi6dhknouvlgktao9o0bg5g41162dp.apps.googleusercontent.com'

const networkPrefixes = {
  MTN: ['0803', '0806', '0703', '0706', '0813', '0816', '0810', '0814', '0903', '0906', '0913', '0916', '07025', '07026', '0704'],
  AIRTEL: ['0802', '0808', '0708', '0812', '0701', '0902', '0901', '0904', '0907', '0912'],
  GLO: ['0805', '0807', '0705', '0815', '0811', '0905', '0915'],
  '9MOBILE': ['0809', '0817', '0818', '0908', '0909'],
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const generateRandomNumber = (n) => {
  return Math.floor(Math.random() * (9 * Math.pow(10, n - 1))) + Math.pow(10, n - 1);
}
export const fetchValidConfirmationEntries = (productId = null, expiryTimeInMinutes = 120)=>{
  const key = 'productData';
  const now = new Date(); // Current date and time
  const storedData = JSON.parse(localStorage.getItem(key)) || [];

  // Filter out expired entries
  const validData = storedData.filter(item => {
    const itemTimestamp = new Date(item.timestamp);
    const expiryDate = new Date(itemTimestamp.getTime() + expiryTimeInMinutes * 60 * 1000);
    return expiryDate > now; // Check if not expired
  });

  // Update localStorage with only valid entries
  localStorage.setItem(key, JSON.stringify(validData));

  // Return entries matching productId and userId if provided
  return validData.filter(item => 
    (!productId || item.productId === productId)
  );
}

export const addNewConfirmationEntry = (productId) => {
  const key = 'productData';
  const now = new Date().toISOString(); // Current date and time in ISO format
  const storedData = JSON.parse(localStorage.getItem(key)) || [];

  // Create a new entry
  const newEntry = { productId, timestamp: now };

  // Add the new entry to the existing data
  storedData.push(newEntry);

  // Save back to localStorage
  localStorage.setItem(key, JSON.stringify(storedData));
}


export const detectNetwork = (phoneNumber) => {
  // Remove any non-digit characters and ensure it starts with 0
  const cleanNumber = phoneNumber.replace(/\D/g, '').replace(/^(234|\+234)/, '0');

  if (cleanNumber.length !== 11) {
    return 'Unknown';
  }

  for (const [network, prefixes] of Object.entries(networkPrefixes)) {
    if (prefixes.some(prefix => cleanNumber.startsWith(prefix))) {
      return network;
    }
  }

  return 'Unknown';
};


export function calculateFee(initFee = 1.7, amount) {
  const feePercentage = initFee/100; 
  const maxFee = 2000; // Maximum fee
  const minFee = 100; // Minimum fee
  const calculatedFee = amount * feePercentage;
  const fee = Math.max(minFee, Math.min(calculatedFee, maxFee)); // Ensures fee is between minFee and maxFee
  return Math.round(fee * 10) / 10; // Rounds to 1 decimal place
}